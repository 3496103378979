<template>
  <div
    v-if="isBannerActive"
    class="banner appearance-extended"
  >
    <div class="banner__content">
      <div class="avatar">
        <img
          alt="avatar"
          class="avatar__image"
          :src="AlvaDesktopHappy"
        />
      </div>
      <div
        class="text"
        v-html="$t('screen.aide.text')"
      />

      <div class="btn">
        <basic-button
          data-track-as="aide-banner"
          :data-track-text="$props.source"
          hierarchy="secondary"
          :label="$t('screen.aide.btn')"
          @click="goto($t('fab.entries.alva.links.alva.url'))"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'

import { BasicButton } from '@/components/Basic'

import AlvaDesktopHappy from '@/assets/alva/Alva_Desktop_froehlich_00.06.svg'

// INIT
defineProps({
  source: {
    type: String,
    required: true,
  },
})

// COMPUTED
const isBannerActive = computed(() => {
  return !partnerStore.isCollective.value && !basketStore.basket.existingCustomer
})

// METHODS
function goto(url) {
  window.location.href = url
}
</script>

<style scoped>
.banner {
  width: 100%;
}
.banner__content {
  display: grid;
  grid-template-areas:
    'avatar text'
    'button button';
  padding: var(--fixed-spacing-fix-04) var(--fixed-spacing-fix-06);
  row-gap: var(--fixed-spacing-fix-01);
  column-gap: var(--fixed-spacing-fix-04);

  border-radius: var(--radius-border-radius-03);
  background: var(--surface-ext-b-medium);

  .avatar {
    grid-area: avatar;

    .avatar__image {
      height: 70px;
    }
  }

  .text {
    grid-area: text;
  }

  .btn {
    grid-area: button;
    text-align: right;
  }
}

@media (--v-large) {
  .banner__content {
    grid-template-columns: min-content auto auto;
    grid-template-areas: 'avatar text button';

    .text,
    .btn {
      align-self: center;
    }
  }
}
</style>
